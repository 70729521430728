import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../../components/layout/layout'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  PageTitle,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import SEO from '../../components/seo'


const Reports = ({ data }) => {
  const reports = data.allMdx.edges

  return (
    <React.Fragment>
      <SEO
        title="Science Classroom Instructional Materials & Development - Reports"
        description="BSCS Science Learning is an independent nonprofit offering science teachers classroom instructional materials & development reports across the US."
        canonicalUrl="https://bscs.org/resources/reports"
      />
      <Layout>
        <section className="section" style={{ padding: '.75rem 1.5rem' }}>
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link className='inline-anchor' to='/'>Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                Resources
              </BreadcrumbItem>
              <BreadcrumbItem>
                Reports
              </BreadcrumbItem>
            </Breadcrumb>
            <PageTitle>Reports</PageTitle>
            <TitleBorder />
            <div style={{ marginBottom: '2rem' }}>
              <div>
                <p>BSCS Science Learning regularly releases reports with insights pertaining to instructional materials development, teacher professional learning, leadership development, and research. See below for a selection of reports.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="flex flex-wrap w-full" style={{ marginBottom: '3rem' }}>
            {
              reports.map((edge, index) => {
                return (
                  <div
                    className="flex w-full justify-center md:w-1/2 lg:w-1/3 xl:w-1/4 mb-3"
                    key={`report-${index}`}
                  >
                    <VerticalCard
                      className="flex w-full"
                      variant="violet"
                      withBackground={true}
                    >
                      <VerticalCardBody className="flex-auto">
                        <h3>{edge.node.frontmatter.title}</h3>
                        <div>
                          <p
                            className="text-bscs-gray-800 mt-3"
                            style={{
                              fontFamily: '"Lora", "Adobe Blank"',
                              fontWeight: '400',
                              fontStyle: 'italic',
                              fontSize: '1rem'
                            }}
                          >
                            <em>{edge.node.frontmatter.reportDate}</em>
                          </p>
                          <TitleBorder variant="violet" />
                          <p
                            className="mt-5 text-bscs-gray-600 tracking-wider text-base"
                            style={{
                              marginBottom: '2rem'
                            }}
                          >
                            {edge.node.frontmatter.cardDescription}
                            {!edge.node.frontmatter.cardDescription && edge.node.excerpt}
                          </p>
                        </div>
                      </VerticalCardBody>
                      <div className="flex w-full p-5 justify-end items-end">
                        <Link
                          to={`/resources/reports/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                        >
                          <span className="bg-white">
                            <Button variant="violet">Read More</Button>
                          </span>
                        </Link>
                      </div>
                    </VerticalCard>
                  </div>
                )
              })
            }
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default Reports

export const reportsQuery = graphql`
  query reportsQuery {
    allMdx(
      filter: {frontmatter: { page: {eq: "reports"}}}
      sort: {
        fields: [frontmatter___sortOrder, frontmatter___title],
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          frontmatter {
            additionalTags,
            cardDescription,
            date,
            sortOrder,
            reportDate,
            title,
            page
          }
        }
      }
    }
  }
`
